import _ from 'lodash';
import { cacheName, deleteOldCaches } from 'src/lib/casrjs/src/casr/cache.js';
import { LocalRecogniserModelTypes, ModelVersion } from './types';
import logger from 'src/lib/logger';

const urlToCacheKey = (url: string) => {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname;
  const key = pathname.substring(pathname.lastIndexOf('/'));
  return key;
};

export const urlToFilename = (url: string) => {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname;
  return pathname.substring(pathname.lastIndexOf('/') + 1);
};

export const getModelmetaForModelInfo = async (modelInfo: any) => {
  const modelmeta = await (await fetch('/modelmeta.json')).json();
  const filesToFind = ['encoder', 'decoder', 'joiner'];
  filesToFind.forEach((name) => {
    const link = _.find(modelInfo.links, (l: string) => l.includes(name));
    if (link) {
      const filename = urlToFilename(link);
      _.set(modelmeta, `${name}.model_basename`, filename);
    }
  });
  if (modelInfo.modelmeta) {
    _.merge(modelmeta, modelInfo.modelmeta);
  }
  return modelmeta;
};

export async function prefetchAllModels(
  allModelsInfo: any,
  progressHandler = _.noop,
) {
  logger.info('[cache] All models load started');
  logger.info('[cache] Loading common files: shared');
  await prefetchModelFiles(
    'shared',
    allModelsInfo['shared'],
    (progress: number) => progressHandler('Loading Common Files', progress),
  );

  for (const modelType of LocalRecogniserModelTypes) {
    const modelInfo = allModelsInfo[modelType];
    if (modelInfo) {
      //take first
      logger.info(`[cache] Loading model files: ${modelType}`);
      await prefetchModelFiles(modelType, modelInfo, (progress: number) =>
        progressHandler('Loading Model Files', progress),
      );

      const modelmeta = await getModelmetaForModelInfo(modelInfo);
      const modelmetaBlob = new Blob([JSON.stringify(modelmeta)], {
        type: 'application/json',
      });
      const modelmetaBlobUrl = URL.createObjectURL(modelmetaBlob);
      await prefetchData('/modelmeta.json', modelmetaBlobUrl);
      URL.revokeObjectURL(modelmetaBlobUrl);
      break;
    }
  }
  logger.info('[cache] All models loaded');
}

export async function prefetchModelFiles(
  type: string,
  modelInfo: any,
  progress?: (progress: number) => void,
) {
  const { version, links } = modelInfo;
  const cacheStorage = await caches.open(cacheName);
  const localModelKey = `${cacheName}-${type}`;

  const localModelInfoString = localStorage.getItem(localModelKey);
  const isNotCached = !localModelInfoString;
  let isOutdated = false;
  if (localModelInfoString) {
    let localModelInfo = {} as any;
    try {
      localModelInfo = JSON.parse(localModelInfoString);
    } catch (error) {}
    if (localModelInfo.version !== version) {
      isOutdated = true;
      localModelInfo.keys.forEach((key: string) => {
        cacheStorage.delete(key);
      });
      localStorage.removeItem(localModelKey);
    }
  }

  if (isNotCached || isOutdated) {
    if (progress) {
      const preflights = await Promise.all(
        links.map((url: string) => preflightRequest(urlToCacheKey(url), url)),
      );
      logger.info(`[cache] preflight done: ${type}`);

      const totalContentLength = preflights.reduce((prev, curr) => {
        const contentLength = parseInt(
          curr.response.headers.get('content-length') || '0',
          10,
        );
        prev += contentLength;
        return prev;
      }, 0);
      let totalLoaded = 0;

      const _p = (t: number, loaded: number) => {
        totalLoaded += loaded;
        progress(totalLoaded / totalContentLength);
      };
      await Promise.all(
        preflights.map((preflight) =>
          cacheResponse(preflight.key, preflight.response, _p),
        ),
      );
    } else {
      await Promise.all(
        links.map((url: string) => prefetchData(urlToCacheKey(url), url)),
      );
    }

    localStorage.setItem(
      localModelKey,
      JSON.stringify({
        type,
        version,
        keys: links.map(urlToCacheKey),
      }),
    );
  }
}

async function preflightRequest(key: string, url: string) {
  logger.info(`[cache] Fetching ${key}: Start`);
  let response: Response;
  try {
    response = await fetch(url);
    logger.info(`[cache] Fetching ${key}: Finished`);
  } catch (error: any) {
    const msg = `Failed to fetch data from ${url}. Status: ${error.message}`;
    logger.error(`[cahce]: ${msg}`);
    throw new Error(msg);
  }
  if (response.ok) {
    return {
      key,
      response,
    };
  } else {
    const msg = `fetch is not ok from ${url}. Status: ${response.status}`;
    logger.error(`[cahce]: ${msg}`);
    throw new Error(msg);
  }
}

async function cacheResponse(
  key: string,
  response: Response,
  progress?: (total: number, loaded: number) => void,
) {
  const cacheStorage = await caches.open(cacheName);
  let responseToCache;
  if (progress) {
    const contentLength = parseInt(
      response.headers.get('content-length') || '0',
      10,
    );
    // Clone the response to read the body
    const reader = response.body!.getReader();

    const stream = new ReadableStream({
      async pull(controller) {
        const { done, value } = await reader.read();

        if (done) {
          controller.close();
          return;
        }

        // Track progress
        progress(contentLength, value.length);
        // Enqueue the chunk to the new stream
        controller.enqueue(value);
      },
    });

    // Create a new response from the custom stream
    const newResponse = new Response(stream, {
      headers: response.headers, // Preserve original headers
    });

    responseToCache = newResponse;
  } else {
    responseToCache = response;
  }

  try {
    await cacheStorage.put(key, responseToCache);
    logger.info(`[cache] Caching ${key}: Finished`);
  } catch (error: any) {
    const msg = `Failed to cache data for ${key}. Status: ${error.message || error}`;
    logger.error(`[cahce]: ${msg}`);
    throw new Error(msg);
  }
}

async function prefetchData(
  key: string,
  url: string,
  progress?: (total: number, loaded: number) => void,
) {
  const preflight = await preflightRequest(key, url);
  await cacheResponse(key, preflight.response, progress);
}

export function clearModelData() {
  for (var key in localStorage) {
    if (key.startsWith(cacheName)) {
      localStorage.removeItem(key);
    }
  }
  deleteOldCaches();
}

export function checkModelsCached(modelType: string) {
  //setting to local storage is done after downloads, so if there is entry -- files are downloaded
  const localModelKey = `${cacheName}-${modelType}`;
  return !!localStorage.getItem(localModelKey);
}

export function getAcousticModelId() {
  for (const modelType of LocalRecogniserModelTypes) {
    const localModelKey = `${cacheName}-${modelType}`;
    const localModelInfoString = localStorage.getItem(localModelKey);
    if (localModelInfoString) {
      let localModelInfo = {} as any;
      try {
        localModelInfo = JSON.parse(localModelInfoString);
      } catch (error) {
        continue;
      }
      return localModelInfo.version;
    }
  }
}

export function getCachedModelVersions(): ModelVersion[] {
  const modelVersions: ModelVersion[] = [];
  for (const modelType of [...LocalRecogniserModelTypes, 'shared']) {
    const localModelKey = `${cacheName}-${modelType}`;
    const localModelInfoString = localStorage.getItem(localModelKey);
    if (localModelInfoString) {
      try {
        const localModelInfo = JSON.parse(localModelInfoString);
        modelVersions.push({ [modelType]: localModelInfo.version });
      } catch (error) {
        continue;
      }
    }
  }
  return modelVersions;
}
