import { UAParser } from 'ua-parser-js';
import _ from 'lodash';

import { getMicNameNaive } from 'src/utils/getMicName';

export const isMac = () => {
  const platform = (
    navigator?.userAgentData?.platform ||
    navigator?.platform ||
    'unknown'
  ).toLowerCase();
  return platform.indexOf('mac') === 0;
};

export const isIPhone = () => {
  const platform = (navigator?.userAgent).toLowerCase();
  return platform.includes('iphone');
};

export const isIPad = () => {
  const platform = (navigator?.userAgent).toLowerCase();
  return platform.includes('ipad');
};

export const isAndroid = () => {
  const platform = (navigator?.userAgent).toLowerCase();
  return platform.includes('android');
};

export const getBasicDeviceInfo = () => {
  const parser = new UAParser();
  const parserResult = parser.getResult();

  return {
    browser_name: _.get(parserResult, 'browser.name'),
    browser_version: _.get(parserResult, 'browser.version'),
    os_name: _.get(parserResult, 'os.name'),
    os_version: _.get(parserResult, 'os.version'),
    device_model: _.get(parserResult, 'device.model'),
    device_type: _.get(parserResult, 'device.type'),
  };
};

export const getLoginEnvInfo = () => {
  const parser = new UAParser();
  const parserResult = parser.getResult();

  return {
    device_info: {
      device_version: [
        _.get(parserResult, 'device.type'),
        _.get(parserResult, 'device.model'),
      ]
        .filter((v) => !!v)
        .join(' '),
      system_name: _.get(parserResult, 'os.name'),
      system_version: _.get(parserResult, 'os.version'),
    },
    client_name: _.get(parserResult, 'browser.name'),
    client_version: _.get(parserResult, 'browser.version'),
  };
};

export const getDeviceInfo = async () => {
  return {
    ...getBasicDeviceInfo(),
    mic_name: await getMicNameNaive(),
  };
};

const isSafariEngine = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const isClipboardCommandSupported = !isSafariEngine();
