/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useAuth from 'src/hooks/useAuth';
import usePrevious from 'src/hooks/usePrevious';
import { update as updateProfile } from 'src/redux/slices/profile';
import { useReduxStorage } from 'src/redux/store';
import logger from 'src/lib/logger';
import { clientApi } from 'src/api/clientApi';

const PROFILE_STATUS_FIELDS = ['is_created', 'is_mandatory_filled'];

const PROFILE_FIELDS = [
  'name',
  'country',
  'gender',
  'diagnosis',
  'speechLevel',
  'phone',
  'region_code',
  'role',
  'referral_source',
];

const initialProfileData = {
  profileStatus: null,
  profileUpdating: false,
};

export const UserProfileContext = createContext(initialProfileData);

export const UserProfileContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, user, updateUser } = useAuth();
  const prevIsAuthenticated = usePrevious(isAuthenticated);
  const dispatch = useDispatch();
  const profileStorage = useReduxStorage('profile');

  const [profileStatus, setProfileStatus] = useState();

  const getProfileStatus = async () => {
    if (!isAuthenticated) return;

    try {
      const data = await clientApi.getProfileStatus();
      const pickedData = _.pick(data, PROFILE_STATUS_FIELDS);

      setProfileStatus(pickedData);
      return pickedData;
    } catch (e) {
      console.error(e);
      enqueueSnackbar(`Error: ${e.message}`, { variant: 'error' });
      logger.error(
        `[contexts/UserProfileContextProvider] getProfileStatus error: ${e.message}`,
      );
    }
  };

  const updateUserProfile = async (updateData) => {
    const profileUpdatesValues = _.pick(updateData, PROFILE_FIELDS);
    if (_.isEmpty(profileUpdatesValues)) {
      return;
    }

    try {
      await dispatch(updateProfile(profileUpdatesValues, user?.name));

      if (profileUpdatesValues.name) {
        updateUser({ name: profileUpdatesValues.name });
      }

      await getProfileStatus();
    } catch (e) {
      console.error(e);

      logger.error(`update profile error: ${e.message}`);
      throw e;
    }
  };

  useEffect(() => {
    const handleAuthenticationChange = async () => {
      const isLoggedOut = !isAuthenticated && prevIsAuthenticated;
      if (isLoggedOut) {
        setProfileStatus(null);
      } else if (user) {
        await getProfileStatus();
      }
    };

    handleAuthenticationChange();
  }, [isAuthenticated, prevIsAuthenticated, user?.id]);

  return (
    <UserProfileContext.Provider
      value={{
        profileStatus,
        profileUpdating: profileStorage.state.isUpdating,
        profile: profileStorage.state.data,
        updateUserProfile,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
