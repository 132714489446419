import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import App from './App';
import { AuthContextProvider } from './contexts/AuthContext';
import { SettingsContextProvider } from './contexts/SettingsContext';
import { UserProfileContextProvider } from './contexts/UserProfileContext';
import { IntegrationsContextProvider } from './contexts/IntegrationsContext';
import { ServerRecognitionContextProvider } from './contexts/ServerRecognitionContext';
import { LocalRecognitionContextProvider } from './contexts/LocalRecognitionContext';
import { InstallAppContextProvider } from './contexts/InstallAppContext';
import { ServerEventsContextProvider } from './contexts/ServerEventsContext';
import { FeatureFlagsContextProvider } from './contexts/FeatureFlagsContext';
import { StyledSnackbarProvider } from './components/StyledSnackbarProvider';
import store from 'src/redux/store';
import handleQueryParams from 'src/lib/handleQueryParams';
import { EventHandlerProvider } from './contexts/EventHandlerContext';

const render = () => {
  const { shouldRenderApp } = handleQueryParams();
  if (!shouldRenderApp) {
    return;
  }

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <BrowserRouter>
      <StyledSnackbarProvider dense maxSnack={3}>
        <ReduxProvider store={store}>
          <EventHandlerProvider>
            <AuthContextProvider>
              <SettingsContextProvider>
                <IntegrationsContextProvider>
                  <ServerRecognitionContextProvider>
                    <LocalRecognitionContextProvider>
                      <UserProfileContextProvider>
                        <FeatureFlagsContextProvider>
                          <ServerEventsContextProvider>
                            <InstallAppContextProvider>
                              <App />
                            </InstallAppContextProvider>
                          </ServerEventsContextProvider>
                        </FeatureFlagsContextProvider>
                      </UserProfileContextProvider>
                    </LocalRecognitionContextProvider>
                  </ServerRecognitionContextProvider>
                </IntegrationsContextProvider>
              </SettingsContextProvider>
            </AuthContextProvider>
          </EventHandlerProvider>
        </ReduxProvider>
      </StyledSnackbarProvider>
    </BrowserRouter>,
  );
};

render();
