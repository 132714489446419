import { createContext, useRef, useEffect, ReactNode, useState } from 'react';
import _ from 'lodash';

import useAuth from 'src/hooks/useAuth';
import usePrevious from 'src/hooks/usePrevious';
import { LocalRecogniser } from 'src/lib/local_recogniser';
import {
  ExecutionProvider,
  LocalRecogniserResult,
  ModelLinks,
} from 'src/lib/local_recogniser/types';
import { clientApi } from 'src/api/clientApi';
import logger from 'src/lib/logger';
import prependWavHeaderToAudio from 'src/utils/prependWavHeaderToAudio';
import {
  APP_BUILD,
  APP_ID,
  AUDIO_CHANNELS_COUNT,
  AUDIO_SAMPLE_RATE,
  DICTATION_PROVIDER,
  WAVE_FORMAT,
} from 'src/constants';
import { getDeviceInfo } from 'src/utils/platform';
import { getAcousticModelId } from 'src/lib/local_recogniser/cache';

type PartialRecognition = {
  full_text?: string;
  text?: string;
  unstable_text?: string;
};

type Recognition = {
  date: Date;
  text?: string;
};

interface Recording {
  text?: string;
  audio: number[];
}

interface InitProgress {
  stage: string;
  progress?: number;
}

interface LocalRecognitionContextValue {
  isAvailable: boolean;
  isInitializing: boolean;
  initProgress?: InitProgress;
  canStream: boolean;
  recognition?: Recognition;
  partial_recognition?: PartialRecognition;
  error?: string;
  isConnected: boolean; //stub
  actions: {
    init: (ep: ExecutionProvider) => Promise<void>;
    stream: (chunk: Array<number>) => void;
    resetRecognition: () => void;

    //stubs
    disconnect: (force: boolean) => void;
    resetAlbCookies: () => void;
    clearSocket: () => void;
    startStreamProcessing: () => void;
    stopStreamProcessing: () => void;
    setRecognitionMode: (m: string) => void;
    setStreamingVadMinSilence: () => void;
    setFilterProfanities: () => void;
    streamCompressedAudio: (blob: Blob) => void;
  };
}

const initialContextValue: LocalRecognitionContextValue = {
  isAvailable: false,
  isInitializing: false,
  initProgress: undefined,
  canStream: false,
  recognition: undefined,
  partial_recognition: undefined,
  error: undefined,
  isConnected: true,
  actions: {
    init: async (ep: ExecutionProvider) => {},
    stream: (chunk: Array<number>) => {},
    resetRecognition: () => {},

    disconnect: () => {},
    resetAlbCookies: () => {},
    clearSocket: () => {},
    startStreamProcessing: () => {},
    stopStreamProcessing: () => {},
    setRecognitionMode: () => {},
    setStreamingVadMinSilence: () => {},
    setFilterProfanities: () => {},
    streamCompressedAudio: (blob: Blob) => {},
  },
};

export const LocalRecognitionContext =
  createContext<LocalRecognitionContextValue>(initialContextValue);

interface LocalRecognitionContextProviderProps {
  children: ReactNode;
}

export const LocalRecognitionContextProvider: React.FC<
  LocalRecognitionContextProviderProps
> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const prevIsAuthenticated = usePrevious(isAuthenticated);

  const recogniserRef = useRef<LocalRecogniser>();
  const recording = useRef<Recording>();
  const recognitionMode = useRef<string>();
  const userId = useRef<number>();

  useEffect(() => {
    userId.current = (user as any)?.id;
  }, [user]);

  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [initProgress, setInitProgress] = useState<InitProgress>();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [modelStatus, setModelStatus] = useState<string>();
  const [error, setError] = useState<string>();
  const [recognition, setRecognition] = useState<Recognition>();
  const [partialRecognition, setPartialRecognition] =
    useState<PartialRecognition>();

  const [isAvailable, setIsAvailable] = useState<boolean>(false);

  const onRecogniserChange = (e: CustomEventInit) => {
    if (e.detail.hasOwnProperty('ready')) {
      setIsReady(e.detail.ready);
    } else if (e.detail.hasOwnProperty('modelStatus')) {
      setModelStatus(e.detail.modelStatus);
    }
  };

  const onDecodeResult = (e: CustomEventInit) => {
    const result: LocalRecogniserResult = e.detail;

    if ('start_of_speech' === result.event) {
      recording.current = {
        text: result.asr?.text,
        audio: result.vad.audio || [],
      };
      const showResult = result.asr?.text.length !== 0;
      if (showResult) {
        setPartialRecognition({
          unstable_text: result.asr?.text,
        });
        setRecognition(undefined);
      }
    } else if ('speech_chunk' === result.event) {
      recording.current = {
        text: result.asr?.text,
        audio: [
          ...(recording.current ? recording.current?.audio : []),
          ...result.vad.audio,
        ],
      };
      const showResult = result.asr?.text.length !== 0;
      if (showResult) {
        setPartialRecognition({
          unstable_text: result.asr?.text,
        });
        setRecognition(undefined);
      }
    } else if ('end_of_speech' === result.event) {
      const text = result.asr?.hasOwnProperty('normalized_text')
        ? result.asr?.normalized_text
        : result.asr?.text;
      recording.current = {
        text,
        audio: [
          ...(recording.current ? recording.current?.audio : []),
          ...result.vad.audio,
        ],
      };
      setPartialRecognition(undefined);
      setRecognition({ date: new Date(), text });

      if (recording.current && recording.current.text) {
        uploadRecording(recording.current);
      }
      recording.current = undefined;
    }
  };

  const onProgress = (e: CustomEventInit) => {
    const result = e.detail;
    setInitProgress({ stage: result.stage, progress: result.progress });
  };

  const resetRecognition = () => {
    setPartialRecognition(undefined);
    setRecognition(undefined);
  };

  const uploadRecording = async (recording: Recording) => {
    const audio = prependWavHeaderToAudio(
      {
        sampleRate: AUDIO_SAMPLE_RATE,
        numChannels: AUDIO_CHANNELS_COUNT,
        bitsPerSample: 32,
        waveFormat: WAVE_FORMAT.IEEE_FLOAT,
      },
      new Float32Array(recording.audio).buffer,
    );

    const date = new Date();
    const fileName = `${userId.current}-${date.getTime()}`;
    const json = {
      file_ext: 'wav',
      result: {
        text: recording.text,
        type: 'recognition',
        recording_date: date.toISOString(),
        duration: recording.audio.length / AUDIO_SAMPLE_RATE,
        provider_id: DICTATION_PROVIDER,
        domain_id: 'beta_phase',
        scenario_id: 'beta_phase',
        app_id: APP_ID,
        app_build: APP_BUILD,
        language: 'en',
        metadata: {
          recognition_tool: 'casrjs',
          ...(await getDeviceInfo()),
        },
        // is_wired: null,
        // is_bluetooth: null,
        acoustic_model_id: getAcousticModelId(),
        recognition_mode: recognitionMode.current,
        confidence: 0,
        confidence_threshold: 0,
        score: 0,
        language_model_id: 0,
        casr_version: '0',
      },
    };

    try {
      await clientApi.uploadRecognition(audio, json, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const recogniser = new LocalRecogniser();
    recogniser.addEventListener('change', onRecogniserChange);
    recogniser.addEventListener('decode', onDecodeResult);
    const throttledOnProgress = _.throttle(onProgress, 5000, {
      leading: true,
      trailing: true,
    });
    recogniser.addEventListener('progress', throttledOnProgress);
    recogniserRef.current = recogniser;

    const init = async () => {
      const isPlatformSupported = await LocalRecogniser.isPlatformSupported();
      setIsAvailable(isPlatformSupported);
    };
    init();
    return () => {
      recogniser.removeEventListener('change', onRecogniserChange);
      recogniser.removeEventListener('decode', onDecodeResult);
      recogniser.removeEventListener('progress', throttledOnProgress);
    };
  }, []);

  // Disconnect on logout
  useEffect(() => {
    const isLoggedOut = !isAuthenticated && prevIsAuthenticated;
    if (isLoggedOut) {
      recogniserRef.current?.cleanUp();

      setIsInitializing(false);
      setIsReady(false);
      setError(undefined);
      setModelStatus(undefined);
      setRecognition(undefined);
      setPartialRecognition(undefined);
    }
  }, [isAuthenticated, prevIsAuthenticated]);

  const init = async () => {
    setIsInitializing(true);
    try {
      setInitProgress(undefined);
      let modelLinks: ModelLinks | undefined;
      try {
        modelLinks = await clientApi.getCasrModelLinks();
      } catch (error) {
        console.error(error);
      }
      await recogniserRef.current?.init(modelLinks);
      setInitProgress(undefined);
      setError(undefined);
    } catch (error: any) {
      logger.error(`Failed to init local recognition: ${error.message}`);
      setInitProgress(undefined);
      setError(error.message);
      setTimeout(() => {
        setError(undefined);
      }, 100);
    }
    setIsInitializing(false);
  };

  const stream = (chunk: Array<number>) => {
    recogniserRef.current?.feed(chunk);
  };

  return (
    <LocalRecognitionContext.Provider
      value={{
        isAvailable,
        isInitializing,
        initProgress,
        canStream: isReady,
        isConnected: isReady,
        recognition,
        partial_recognition: partialRecognition,
        error,
        actions: {
          init,
          stream,
          resetRecognition,

          disconnect: () => {},
          resetAlbCookies: () => {},
          clearSocket: () => {},
          startStreamProcessing: () => {},
          stopStreamProcessing: () => {},
          setRecognitionMode: (m: string) => (recognitionMode.current = m),
          setStreamingVadMinSilence: () => {},
          setFilterProfanities: () => {},
          streamCompressedAudio: (blob: Blob) => {},
        },
      }}
    >
      {children}
    </LocalRecognitionContext.Provider>
  );
};
